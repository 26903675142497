<template>
  <div>
    <b-card>
      <b-row class="d-flex d-inline-block">
        <b-col md="3" class="d-flex justify-content-between flex-column">
          <b-list-group>
            <div v-if="environments">
              <b-list-group-item
                class="text-center cursor-pointer"
                @click="addEnvironment()"
                v-if="!userCantEdit"
              >
                <feather-icon v-if="!loadingAdd" icon="PlusIcon" />
                <b-spinner
                  v-else
                  style="width: 1rem; height: 1rem"
                  label="Loading..."
                />
              </b-list-group-item>
              <b-list-group-item
                v-for="(environment, index) in environments"
                :key="environment.id"
                class="d-flex justify-content-between"
                :class="isSelected(index)"
                :active="activeEnvironment == environment.id"
                v-on:click="selectedList = index"
                :ref="index == 0 ? 'firstActive' : ''"
                @click="loadEnvironmentById(environment)"
              >
                <span
                  class="align-text-bottom line-height-1"
                  v-if="editing == environment.id ? false : true"
                  >{{ environment.name }}</span
                >
                <div
                  class="d-inline-block"
                  v-if="editing == environment.id ? true : false"
                >
                  <b-form-input
                    autofocus
                    size="md"
                    v-model="environment.name"
                    @blur.self="toggleEditingEnv(environment)"
                    type="text"
                  ></b-form-input>
                </div>
                <div class="d-inline-block dj-node-button" v-if="!userCantEdit">
                  <b-button
                    v-if="loadingEnv == environment.id ? false : true"
                    class="btn p-25"
                    variant="none"
                    size="md"
                    @blur="dropdown = false"
                    @click="dropdown = !dropdown"
                    
                  >
                    <feather-icon icon="MoreVerticalIcon" />

                    <ul
                      v-if="dropdown && environment.id == activeEnvironment"
                      class="dj-node-button-dropdown"
                    >
                      <li
                        class="dj-node-button-item p-50"
                        @click="toggleEditingEnv(environment)"
                      >
                        <small class="text-left">Edit</small>
                      </li>
                      <li
                        class="dj-node-button-item p-50"
                        @click="cloneEnvironment(environment)"
                      >
                        <small class="text-left">Clone</small>
                      </li>
                      <li
                        class="dj-node-button-item p-50"
                        @click="deleteEnvironment(environment)"
                      >
                        <small class="text-left">Delete</small>
                      </li>
                    </ul>
                  </b-button>
                  <b-button v-else class="btn p-25" variant="none" size="md">
                    <b-spinner
                      style="width: 1rem; height: 1rem"
                      label="Loading..."
                    />
                  </b-button>
                </div>
              </b-list-group-item>
            </div>
            <div v-else>
              <b-list-group-item
                v-for="n in 10"
                :key="n"
                class="d-flex justify-content-between"
              >
                <b-skeleton
                  animation="wave"
                  width="80%"
                  height="15px"
                ></b-skeleton>
                <b-skeleton
                  type="button"
                  animation="wave"
                  width="10%"
                  height="15px"
                ></b-skeleton>
              </b-list-group-item>
            </div>
          </b-list-group>
          <div
            v-if="links && paginator"
            class="d-flex justify-content-between mt-2"
          >
            <b-button
              :disabled="!links.previous"
              variant="primary"
              @click="newPage(-1)"
            >
              <feather-icon icon="ArrowLeftIcon" />
            </b-button>
            <strong>{{
              `${paginator.current_page} ${$t("common.terms.out_of")}  ${
                paginator.pages
              }`
            }}</strong>
            <b-button
              :disabled="!links.next"
              variant="primary"
              @click="newPage(1)"
            >
              <feather-icon icon="ArrowRightIcon" />
            </b-button>
          </div>
        </b-col>
        <b-col md="9" class="d-flex justify-content-between flex-column">
          <json-editor
            key="environments"
            v-model="json"
            :modes="['code', 'tree', 'preview']"
            :height="600"
          />
          <b-row class="d-flex justify-content-end mt-1">
            <b-button class="mr-2" variant="primary" @click="handleSubmit()" v-if="!userCantEdit">
              <feather-icon icon="CheckIcon" />
              {{ $t("common.terms.submit") }}
            </b-button>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
    <l-overlay />
  </div>
</template>

<script>
import {
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BCard,
  BCol,
  BButton,
  BListGroup,
  BListGroupItem,
  BRow,
  BSkeleton,
  BSpinner,
} from "bootstrap-vue";

import LOverlay from "@/layouts/components/LoadingOverlay";
import { makeToast } from "@/layouts/components/Popups.js";
import JsonEditor from "@/layouts/components/JsonEditor/JsonEditor.vue";

export default {
  components: {
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BCard,
    BCol,
    BRow,
    BButton,
    BListGroup,
    BListGroupItem,
    BSkeleton,
    LOverlay,
    BSpinner,
    JsonEditor,
  },

  data() {
    return {
      json: {},
      environments: null,
      paginator: null,
      links: null,
      loadedEnvironment: null,
      activeEnvironment: null,
      dropdown: false,
      editing: null,
      currentPage: 1,
      loadingEnv: 0,
      loadingAdd: false,
      selectedList: 0,
      count: 0,
    };
  },

  mounted() {
    this.init();
  },
  computed: {
    transmissionID() {
      return this.$route.params.transmissionID;
    },
    userCantEdit(){
      return !this.$can('create')
    }
  },
  methods: {
    init() {
      this.fetchAllEnvironments(this.currentPage);
    },
    isSelected(i) {
      if (this.count == 0) {
        if (i == 0) {
          this.loadEnvironmentById(this.environments[0]);
        }
        this.count = 1;
        return i === this.selectedList;
      }
    },
    newPage(direction) {
      this.environments = null;
      this.fetchAllEnvironments(this.currentPage + direction);
    },
    fetchAllEnvironments(page) {
      this.$store
        .dispatch("getAllEnvironmentsByTransmissionGroup", {
          page: page,
          per_page: 100,
          transmissionID: this.transmissionID,
        })
        .then((response) => {
          this.environments = response.items;
          this.paginator = response.paginator;
          this.links = response.links;
        })
        .catch((error) => {
          makeToast({
            title: this.$t("environments.toast.fetch.title"),
            text: this.$t("environments.toast.fetch.message"),
            variant: "danger",
            icon: "XIcon",
          });
        });
    },
    loadEnvironmentById(environment) {
      if (this.activeEnvironment == environment.id) {
        return;
      }
      return new Promise((resolve, reject) => {
        this.loadingEnv = environment.id;
        this.setActiveEnvironment(environment.id);
        this.$store
          .dispatch("getEnvironmentById", {
            envID: environment.id,
            transmissionID: this.transmissionID,
          })
          .then((response) => {
            this.loadedEnvironment = environment;
            this.json = response.vars instanceof Array ? {} : response.vars;
            this.script = response;
            this.loadingEnv = 0;
            resolve(true);
          })
          .catch((error) => {
            this.loadingEnv = 0;
            makeToast({
              title: this.$t("environments.toast.get_by_id.title"),
              text: this.$t("environments.toast.get_by_id.message"),
              variant: "danger",
              icon: "XIcon",
            });
            reject(false);
          });
      });
    },
    addEnvironment(name = null) {
      return new Promise((resolve, reject) => {
        this.loadingAdd = true;
        this.$store
          .dispatch("addEnvironment", {
            name,
            index: Object.keys(this.environments).length,
            transmissionID: this.transmissionID,
          })
          .then((response) => {
            this.environments.unshift(response);
            this.loadingAdd = false;

            makeToast({
              title: this.$t("environments.toast.add.success.title"),
              text: this.$t("environments.toast.add.success.message"),
              variant: "success",
              icon: "CheckIcon",
            });
            resolve(response);
          })
          .catch((error) => {
            this.loadingAdd = false;
            makeToast({
              title: this.$t("environments.toast.add.error.title"),
              text: this.$t("environments.toast.add.error.message"),
              variant: "danger",
              icon: "XIcon",
            });
            reject(error);
          });
      });
    },
    async handleSubmit() {
      if (Object.keys(this.json).length > 0 && this.loadedEnvironment) {
        this.$store.dispatch("setLoadingState", true);
        this.script.env = await this.json;
        this.$store
          .dispatch("importEnvironmentVars", {
            script: this.script,
            environment: this.loadedEnvironment,
            transmissionID: this.transmissionID,
          })
          .then(() => {
            this.$store.dispatch("setLoadingState", false);
            makeToast({
              title: this.$t("environments.toast.insert.success.title"),
              text: this.$t("environments.toast.insert.success.message"),
              variant: "success",
              icon: "CheckIcon",
            });
            return true;
          })
          .catch(() => {
            this.$store.dispatch("setLoadingState", false);
            makeToast({
              title: this.$t("environments.toast.insert.error.title"),
              text: this.$t("environments.toast.insert.error.message"),
              variant: "danger",
              icon: "XIcon",
            });
            return false;
          });
      }
    },
    setActiveEnvironment(envId) {
      this.activeEnvironment = envId;
    },
    deleteEnvironment(env) {
      this.$bvModal
        .msgBoxConfirm(
          this.$t("environments.actions.delete", { name: env.name }),
          {
            title: this.$t("environments.actions.delete.title"),
            okTitle: this.$t("common.modal.ok"),
            okVariant: "primary",
            cancelTitle: this.$t("common.modal.cancel"),
            cancelVariant: "outline-danger",
            size: "md",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.$store.dispatch("setLoadingState", true);

            this.$store
              .dispatch("deleteEnvironment", {
                envID: env.id,
                transmissionID: this.transmissionID,
              })
              .then(() => {
                this.init();
                this.$store.dispatch("setLoadingState", false);

                makeToast({
                  title: this.$t("environments.toast.delete.success.title"),
                  text: this.$t("environments.toast.delete.success.message"),
                  variant: "success",
                  icon: "CheckIcon",
                });
              })
              .catch(() => {
                this.$store.dispatch("setLoadingState", false);

                makeToast({
                  title: this.$t("environments.toast.delete.error.title"),
                  text: this.$t("environments.toast.delete.error.message"),
                  variant: "danger",
                  icon: "XIcon",
                });
              });
          }
        });
    },
    toggleEditingEnv(env) {
      if (this.editing) {
        this.$store.dispatch("setLoadingState", true);
        this.$store
          .dispatch("editEnvironmentName", {
            envId: env.id,
            envName: env.name,
            transmissionID: this.transmissionID,
          })
          .then((response) => {
            this.editing = false;
            this.$store.dispatch("setLoadingState", false);
          })
          .catch((error) => {
            this.$store.dispatch("setLoadingState", false);
          });
      } else {
        this.editing = env.id;
      }
    },
    async cloneEnvironment(env) {
      this.addEnvironment(`Clone ${env.name}`).then(async (response) => {
        if (Object.keys(this.json).length > 0) {
          this.script.env = await this.json;
          this.$store
            .dispatch("cloneEnvironment", {
              script: this.script,
              env: response,
            })
            .then(() => {
              this.loadEnvironmentById(response);
              makeToast({
                title: this.$t("environments.toast.clone.success.title"),
                text: this.$t("environments.toast.clone.success.message"),
                variant: "success",
                icon: "CheckIcon",
              });
            })
            .catch(() => {
              makeToast({
                title: this.$t("environments.toast.clone.error.title"),
                text: this.$t("environments.toast.clone.error.message"),
                variant: "danger",
                icon: "XIcon",
              });
              return false;
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/variables/_variables.scss";

.dj-node-button {
  .dj-node-button-dropdown {
    // display: none;
    // opacity: 0;
    position: absolute;
    padding: 10px;
    border-radius: 5px;
    background: $backgroundDark;
    margin-top: 7px;
    transition: all 0.2s ease-out;

    .dj-node-button-item {
      list-style: none;
      color: $white;
      padding: 5px;
    }
  }
  // &:hover{
  //   .dj-node-button-dropdown{
  //     display: block;
  //     opacity: 1;
  //   }
  // }
}

.jsoneditor-outer {
  min-height: 300px !important;
  height: 60vh !important;
}
</style>

